import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  FormControl,
  Input,
  InputLabel,
  Menu,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import axios from "axios";
import { API_URL, popupTimer } from "../../consts";
import { useDispatch } from "react-redux";
import ConfirmationDialog from "../common/ConfirmationDialog";
import ChipList from "../common/ChipList";
import HomeCardItem from "./HomeCardItem";
import { useSelector } from "react-redux";
import ExtensionLoginSuccess from "../Extension/ExtensionLoginSuccess";
import { useNavigate } from "react-router-dom";

const Reviews = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  let navigate = useNavigate();

  const [searhTags, setSearchTags] = useState([]);
  const [searchItem, setsearchItem] = useState("");
  const [filterBy, setFilterBy] = useState("all");
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [removedRateId, setRemovedRateId] = useState(null);
  useEffect(() => {
    const handleBeforeUnload = () => {
      // Delete specific item from local storage
      localStorage.removeItem('yourItemKey');
    };

    // Add event listener for page refresh/unload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const deleteRate = () => {
    let url = API_URL + `/rates/${removedRateId}/delete`;
    axios
      .post(url, null)
      .then((res) => {
        getRatesRequest(null, filterBy, searhTags);
        setTimeout(() => {
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: "",
              type: "",
            },
          });
        }, popupTimer);
        dispatch({
          type: "SET_POPUP",
          payload: {
            text: "Rate removed successfully",
            type: "success",
          },
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch({
            type: "SET_UNAUTHORIZED",
          });
          return;
        }
        dispatch({
          type: "SET_POPUP",
          payload: {
            text: err.response.data.error
              ? err.response.data.error
              : "Something Went Wrong. Please Try Again Later",
            type: "error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: "",
              type: "",
            },
          });
        }, popupTimer);
      });
  };

  const onRateSuccessfully = (id) => {
    let url = API_URL + `/rates/${id}`;
    axios
      .get(url)
      .then((res) => {
        const ratedObj = res.data.rated_object.rate;
        setData(data.map((obj) => (obj.id === id ? (obj = ratedObj) : obj)));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch({
            type: "SET_UNAUTHORIZED",
          });
          return;
        }
        dispatch({
          type: "SET_POPUP",
          payload: {
            text: err.response.data.error
              ? err.response.data.error
              : "Something Went Wrong. Please Try Again Later",
            type: "error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: "",
              type: "",
            },
          });
        }, popupTimer);
      });
  };
  const token = useSelector((state) => state.token);

  useEffect(() => {
    if (token) {

      getRatesRequest(null, filterBy, searhTags)
    }

  }, [token]);
  useEffect(() => {
    let isExtension = localStorage.getItem('is_extension_login')
    if (isExtension) {
      navigate('/extension-login-success')

    }

  }, []);

  useEffect(() => {
    getRatesRequest(null, filterBy, searhTags);

  }, [filterBy, searhTags, token]);

  const getRatesRequest = (search_query, search_type, tags) => {
    let url = API_URL + `/rates/my-rates`;
    let paramsBody = {
      tags: tags.length > 0 ? tags.join(',') : null,
    };
    axios
      .get(url, {

        params: paramsBody,
      })
      .then((res) => {
        setData(res.data.rates);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: "SET_UNAUTHORIZED",
          });
          return;
        }
        console.log(err)
        dispatch({
          type: "SET_POPUP",
          payload: {
            text: err.response.data.error
              ? err.response.data.error
              : "Something Went Wrong. Please Try Again Later",
            type: "error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: "",
              type: "",
            },
          });
        }, popupTimer);
      });
  };

  const onTagAdded = (e) => {
    if (e.key === "Enter") {
      let newSearcgTags = [...searhTags, searchItem];
      setSearchTags(newSearcgTags);
      setsearchItem("");
    }
  };

  const handleSearchTagDelete = (index) => {
    let newSearcgTags = searhTags.filter((f, i) => i !== index);
    setSearchTags(newSearcgTags);
  };

  const selectFromPrev = (item) => {
    let newSearcgTags = [...searhTags, item];
    setSearchTags(newSearcgTags);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenFilterMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuCheckbox = (name) => {
    setFilterBy(name);
    setAnchorEl(null);
  };

  return (
    <Grid container spacing={2} style={{ paddingTop: "34px", width: "100%" }}>
      <Grid item xs={12}>
        <h2>My Reviews</h2>
        <FormControl className="searchbar" fullWidth variant="standard">
          <InputLabel htmlFor="standard-adornment-password">Search</InputLabel>
          <Input
            fullWidth
            onKeyDown={onTagAdded}
            className="searchBox"
            id="standard-adornment-password"
            value={searchItem}
            onChange={(e) => setsearchItem(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
        <ChipList list={searhTags} onDelete={handleSearchTagDelete} />
        {!token && (
          <div style={{ textAlign: 'center', padding: '20px', fontSize: '18px' }}>
            <p>Please login to view your rates</p>
            <button
              onClick={() => window.location.href = '/login'}
              style={{
                backgroundColor: '#4CAF50',
                color: 'white',
                padding: '10px 20px',
                fontSize: '16px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer'
              }}
            >
              Login
            </button>
          </div>
        )}
      </Grid>
      {data.map((m, index) => (
        <Grid item xs={12} sm={4} md={4} key={index}>
          <HomeCardItem

            isBookmark={true}
            data={m}
            searhTags={searhTags}
            applyMargin={true}
            showRemove={true}
            onRemove={(id) => {
              setRemoveDialogOpen(true);
              setRemovedRateId(id);
            }}
            onRateSuccessfully
          />

        </Grid>
      ))}
      <ConfirmationDialog
        open={removeDialogOpen}
        onClose={() => setRemoveDialogOpen(false)}
        onConfirm={() => {
          deleteRate();
          setRemoveDialogOpen(false);
        }}
        title={"Delete rate"}
        cancelButtonString={"Cancel"}
        confirmButtonString={"Delete"}
        message={"Are you sure you want to delete this rate?"}
      />
    </Grid>
  );
};

export default Reviews;
