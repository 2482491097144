import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./Redux/store";
import Amplify from "aws-amplify";
import { Authenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import config from "./config.json";

const isLocalhost = false;
let redirectUrl;
if (window.location.pathname === "/login") {
  console.log(window.location.search.includes("is_extension"))
  if (window.location.search.includes("is_extension")) {
    console.log('set  ')
    localStorage.setItem('is_extension_login',true)
    const params = new URLSearchParams(window.location.search);
    // redirectUrl = "https://www.app.datarate.io/extension-login-success";

    redirectUrl = "https://www.app.datarate.io";
  } else {
    redirectUrl = "https://www.app.datarate.io";
  }
}
else {
  redirectUrl = "https://www.app.datarate.io";
}
// redirectUrl = "http://localhost:3000";

// redirectUrl='http://localhost:3000/extension-login-success'
// console.log('redi')
console.log(redirectUrl)

// Boolean(
//     window.location.hostname === "localhost" ||
//     // [::1] is the IPv6 localhost address.
//     window.location.hostname === "[::1]" ||
//     // 127.0.0.1/8 is considered localhost for IPv4.
//     window.location.hostname.match(
//       /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
//     )
// );

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: {
      domain: [config.cognito.USER_POOL_DOMAIN],
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: isLocalhost ? "http://localhost:3000" : redirectUrl,
      redirectSignOut: isLocalhost
        ? "http://localhost:3000"
        : config.cognito.URL_REDIRECT_SIGNOUT,
      responseType: "code",
    },
  },
});

<Authenticator usernameAlias="email" />;

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
